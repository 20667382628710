<template>
  <Layout is-demo>
    <h1 class="text-h4 ms-0 mb-1">Dashboard</h1>

  </Layout>
</template>

<script>
import Layout from '@/nfmp_components/layouts/MainPortalLayout'
import httpServ from '../services/HTTPService'
//import { Plotly } from 'vue-plotly'

export default {
  components: {
    Layout
  },
  data() {
    return {
      alterLOBFlag: false,
      someDate:'2023-05-03 00:00:00',
      memberData: {},
      dashboardData: {
        phone: 'loading...',
        email: 'loading...',
        memberId:'loading...',
        surname:'loading...'
      }
    }
  },
  created() {
    /*this.memberData = httpServ.getSessionJson('memberData')
    //console.log(this.memberData)
    this.currentLOBDetails.memberStatus =
      this.memberData.lobs[this.memberData.displayLOB].membership_status
    this.currentLOBDetails.LOBId =
      this.memberData.lobs[this.memberData.displayLOB].line_of_business_id
    this.currentLOBDetails.LOBname =
      this.memberData.lobs[this.memberData.displayLOB].line_of_business_name
    this.prePostData = {
      //jwtToken: this.memberData.jwt,
      membership_id: this.memberData.membership_id,
      LOB_id:
        this.memberData.lobs[this.memberData.displayLOB].line_of_business_id
    }
    if (this.currentLOBDetails.memberStatus === 'Firstrun') {
      this.getDisplayData4FR()
      this.getTableData(this.memberData.displayLOB)
    } else {
      console.log('get FullMembership data')
    }*/
    
  }
}
</script>

<style scoped>
.v-alert--prominent .v-alert__icon.v-icon {
  font-size: 32px;
  color: green;
}
.v-alert {
  color: green !important;
}
.card-header-text {
  font-weight: 400 !important;
  color: rgba(0, 0, 0, 0.87);
  padding-bottom: 20px;
}
.d-card-height {
  min-height: 500px !important;
}

::v-deep .v-data-table td {
  height: 20px !important;
  font-size: 14px !important;
}

::v-deep .v-data-table th {
  font-size: 12px !important;
}

.reg-guide-text {
  font-size: 13px !important;
}
</style>
