<template>
  <Layout is-demo>
    <div class="">
      <h1 class="text-h4">Farmers Registration</h1>
      <v-divider class="my-2"></v-divider>
    </div>
    <v-form ref="form" v-model="valid" fast-fail>

      <v-card class="mb-3">
        <v-card-title>Farmer's Bio</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="3">
              <v-text-field
                v-model="first_name"
                label="Farmer First Name *"
                :rules="namesRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <v-text-field
                v-model="surname"
                label="Farmer Surname *"
                :rules="namesRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <v-text-field
                v-model="phone"
                :rules="phoneRules"
                :counter="11"
                label=" Phone Number *"
                maxlength="11"
                required
              ></v-text-field>
            </v-col>
            <v-col class="d-flex" cols="12" sm="12" md="3">
              <v-select
                v-model="gender"
                :items="gender_arr"
                label="Gender"
                item-text="title"
                item-value="id"
                :rules="[(v) => !!v || 'Item is required']"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-select
                v-model="state"
                return-object
                item-text="title"
                item-value="id"
                :items="state_arr"
                :rules="[(v) => !!v || 'Item is required']"
                label="Residence State *"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-select
                v-model="lga"
                return-object
                item-text="title"
                item-value="id"
                :items="lga_arr"
                :rules="[(v) => !!v || 'Item is required']"
                label="Residence LGA *"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                v-model="ward"
                label="Ward *"
                :rules="namesRules"
                hint="Must be at least 4 character long"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="home_address"
                :rules="addressRules"
                label="Home Address *"
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mb-3">
        <v-card-title>Identification</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="4">
              <v-select
                v-model="means_of_identification_type"
                return-object
                item-text="title"
                item-value="id"
                :items="means_of_identification_type_arr"
                :rules="[(v) => !!v || 'Item is required']"
                label="Means of Identification *"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                v-model="identification_id"
                :rules="[(v) => !!v || 'Item is required']"
                label="ID Number *"
                hint="ID Number"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-select
                v-model="cooperative"
                return-object
                item-text="cooperative_name"
                item-value="id"
                :items="cooperative_arr"
                label="Cooperative"
                required
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mb-3">
        <v-card-title>Farm details</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="8">
              <v-text-field
                v-model="farm_address"
                :rules="addressRules"
                label="Farm location/address *"
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                v-model="farm_size"

                label="Farm Size (hecteres)*"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="farm_borders"
                :rules="addressRules"
                label="Farm Borders/Landmarks *"
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
            
      <v-card class="mb-3">
        <v-card-title>Farm GRS Coordinates</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                v-model="longitude"
                label="Longitude *"
                :rules="coordinateRules"
                required
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                v-model="latitude"
                label="Latititude *"
                :rules="coordinateRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-btn
                rounded
                block
                :loading="loadingGPS"
                :disabled="loadingGPS"
                color="primary"
                large
                @click="getGPS()"
              >
                Get Coordinates
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mb-3">
        <v-card-title>Value Chain Commodities</v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="12">
              <h2 class="text-h6 mb-2">
                Select the ones you are producing 
              </h2>
              <v-chip-group
                v-model="selections"
                center-active
                multiple
                column
                active-class="green--text text--accent-5 "
              >
                <v-chip
                  v-for="item in comodities_arr"
                  :key="item.id"
                  :value="item.id"
                  filter
                  outlined
                >
                  {{ item.title }}
                </v-chip>
              </v-chip-group>
            </v-col>
            
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mb-3">
        <v-card-title>Group Lead</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-select
                v-model="lead_type"
                :items="lead_type_arr"
                item-text="title"
                item-value="id"
                label="Lead Type/Status"
                :rules="[(v) => !!v || 'Item is required']"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                v-model="lead_phone"
                :rules="phoneRules"
                :counter="11"
                label="Lead Phone Number *"
                maxlength="11"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                v-model="lead_first_name"
                label="Lead First Name *"
                :rules="namesRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                v-model="lead_surname"
                label="Lead Surname *"
                :rules="namesRules"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-row justify="center" align="center">
        <v-col cols="12" sm="12" md="6" class="text-center">
          <v-btn
            rounded
            block
            color="primary"
            large
            @click="showDialog()"
          >
            Submit 
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-snackbar v-model="snackbar" right>
      {{ snackbartext }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-toolbar color="green" dark dense flat>
          <v-toolbar-title class="text-h5">
            Save Famer
          </v-toolbar-title>
        </v-toolbar>
        <v-card-subtitle class="mt-3">Are sure you want to save this record?</v-card-subtitle>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="close">
            Close
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            outlined
            @click="postData()"
          >
            Save Data
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </Layout>
</template>

<script>
import Layout from '@/nfmp_components/layouts/MainPortalLayout'
import httpServ from '../services/HTTPService'

export default {
  components: {
    Layout
  },
  data() {
    return {
      memberData: {},
      currentLOBDetails: {},
      prePostData: {},
      pageData: {},
      snackbar: false,
      snackbartext:'',
      postStatus: 'new',

      isNotLoaded: true,
      absolute: true,
      opacity: 1,
      overlay: false,
      dense: false,
      fixedHeader: false,
      height: 300,
      loading: false,
      loadingGPS: false,
      dialog:false,
    
      first_name:'',
      surname:'',
      phone:'',
      gender:'',
      state:'',
      lga:'',
      ward:'',
      home_address:'',
      means_of_identification_type:'',
      identification_id:'',
      cooperative:'',
      farm_address:'',
      farm_size:'',
      farm_borders:'',
      longitude:'0.00',
      latitude:'0.00',
      comodities_arr:[],
      lead_type:'',
      lead_phone:'',
      lead_first_name:'',
      lead_surname:'',

      gender_arr: [],
      means_of_identification_type_arr:[],
      cooperative_arr: [],
      state_arr: [],
      state_model: '',
      lead_type_arr:[],
      lga_arr: [],
      all_lga_arr:[],
      lga_model: '',
      selections: [],
      products_arr:[],
      lead_arr: ['Village head','Value chain leader'],
      IDNumber:'',
    
      valid: '',

      formItems: [],
      formId: 0,
      stateItems: [],
      stateItemsId: 0,
    
      items: [],

      cooperativeNameRules: [
        (v) => !!v || 'Corperative name is required'
      ],
      namesRules: [
        (v) => !!v || 'Name is required',
        (v) =>
          (v && v.length >= 2) ||
        'Mame must be greater than 2 characters'
      ],
      phoneRules: [
        (v) => !!v || 'Phone Number is required',
        (v) => /^\d{11}$/.test(v) || 'Phone number be must be valid',
        (v) => (v && v.length <= 11) || 'Phone Number must be 11 figures'
      ],
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      addressRules: [
        (v) => !!v || 'Name is required',
        (v) =>
          (v && v.length >= 15) ||
        'Mame must be greater than 15 characters'
      ],
      numberRules: [
        (v) => !!v || 'Tonnage is required',
        (v) => !isNaN(parseFloat(v)) || 'Tonnage must be a number',
        (v) => (v && v > 0) || 'Tonnage must greater than zero'
      ],
      coordinateRules: [
        (v) => !!v || 'This coordinates is required',
        (v) => !isNaN(parseFloat(v)) || 'This coordinates must be a number',
        (v) => (v && v > 0) || 'This coordinates must greater than zero'
      ]
    }
  },
  watch: {
    state: {
      handler(newvalue) {
        this.lga_arr = this.all_lga_arr.filter(
          (lga) => lga.state_id === this.state.id
        )
      },
      immediate: true
    },
    lga: {
      handler(newvalue) {
        this.getCooperative(newvalue.id)
      },
      immediate: true
    }
  },
  created() {
    this.memberData = httpServ.getSessionJson('memberData')
    this.getFormOption()

  },
  mounted() {},
  methods: 
  {
    close() {
      this.dialog = false
    },
    showDialog: function() {
      this.dialog = true
    },
    getGPS: function() {
      this.loadingGPS = true
      const success = (position) => {
        this.latitude  = position.coords.latitude
        this.longitude = position.coords.longitude
        this.loadingGPS = false
      }

      const error = (err) => {
        console.log(err)
        this.loadingGPS = false
      }

      navigator.geolocation.getCurrentPosition(success, error)
    },
    getCooperative: function(lga) {
      if (lga > 0) {
        const formUrl = httpServ.getFarmerEnumerationtUrl('/enumeration/drySession/formOptions/cooperativeByLGA')
        const resp = httpServ.postFetch(formUrl,{ 'lga_rf':lga })
      
        resp.then((result) => {
          console.log('cooperative select is ', result)
          if (result.status === 200) {
            this.cooperative_arr = result.data
          } else {
            this.snackbartext = result.message
            this.snackbar = true
          }
        })
      }
    },
    getFormOption: function() {
      const formUrl = httpServ.getFarmerEnumerationtUrl('/enumeration/drySession/formOptions/bios')
      const resValue2 = httpServ.makeRequest(formUrl, 'GET')
      
      resValue2.then((result) => {
        console.log('our select is ', result)
        if (result.status === 200) {
          this.state_arr = result.data.states
          this.all_lga_arr = result.data.lgas
          this.means_of_identification_type_arr = result.data.identifications
          this.gender_arr = result.data.genders
          this.lead_type_arr = result.data.leads
          this.comodities_arr = result.data.comodities
        } else {
          //alert(result.message)
          this.snackbartext = result.message
          this.snackbar = true
        }
      })
    },
    getNewData: function() {
      console.log(this.lead_type)
      const data = {
        status: 'new',
        enumerator:'tttt',
        first_name:this.first_name,
        surname:this.surname,
        phone:this.phone,
        gender_rf:this.gender,
        state_rf:this.state.id,
        lga_rf:this.lga.id,
        ward:this.ward,
        home_address:this.home_address,

        means_of_identification_type_rf:this.means_of_identification_type.id,
        identification_id:this.identification_id,
        cooperative_id:this.cooperative.id,

        farm_address:this.farm_address,
        farm_size:this.farm_size,
        farm_borders:this.farm_borders,

        longitude:this.longitude,
        latitude:this.latitude,
        comodities:this.selections,

        lead_type_rf:this.lead_type,
        lead_type:this.lead_type,
        lead_phone:this.lead_phone,
        lead_first_name:this.lead_first_name,
        lead_surname:this.lead_surname
      }

      return data
    },
    getUpdatedData: function() {
      const data = {}
      
      return data
    },
    postData: function() {
      this.close()
      
      //validate selection
      if (this.selections.length === 0) {
        this.snackbartext = 'One or more comodity must be selected!'
        this.snackbar = true

        return
      }

      const postData = this.postStatus === 'new' ? this.getNewData() : this.getUpdatedData()

      console.log(postData)

      if (this.$refs.form.validate()) {
        const url = httpServ.getFarmerEnumerationtUrl('/enumeration/drySession/saveFarmer')
        const resp = httpServ.postFetch(url,postData)

        resp.then((result ) => {
          if (result.status === 200) {
            this.snackbartext = 'Your submission was successful'
            this.snackbar = true
            window.location.reload()
          }  else if (result.status === 422) {
            
            if (result instanceof SyntaxError) {
              // Unexpected token < in JSON
              //console.log('There was a SyntaxError', result)//checker
              this.snackbartext = result.message
              this.snackbar = true
            } else {
              //console.log('There was an error', result) //Checker
              this.snackbartext = result.message
              this.snackbar = true

            }
            this.formBtnLoader = false
          } else {
            this.snackbartext = result.message 
            this.snackbar = true
          }
        })
      }
    }
  }
}
</script>

<style scoped></style>
